<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0"><b>استبيان رضا العملاء</b></h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row-wrap>
              <v-flex xs12 md12>
                <p style="font-size:18px;">
                  عزيزي العميل: سعيا من شركة إسمنت اليمامة لتقديم أفضل الخدمات
                  لعملائها الكرام, لذا نرجو منكم تقييم مستوى الخدمة المقدمة لكم
                  من خلال إكمال الإستبيان أدناه,
                </p>
                <p style="font-size:18px;">
                  ونود إشعاركم بأننا سوف نولي جميع ملاحظاتكم ما تستحق من اهتمام.
                </p>
                <p style="float:left">التاريخ: {{ date }}</p>
              </v-flex>
            </v-layout>
            <v-divider />
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout wrap row>
                <v-flex xs12 sm6>
                  <v-select
                    v-model="externalsType"
                    :items="externalsTypes"
                    @change="getExternals"
                    item-text="item"
                    item-value="value"
                    label="نوع الجهة الخارجية"
                    :rules="externalsTypeRules"
                    persistent-hint
                    return-object
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-autocomplete
                    :items="externals"
                    v-model="external"
                    :filter="customFilter"
                    color="white"
                    item-text="item"
                    label=" الجهة الخارجية"
                    :rules="externalsRules"
                    item-value="value"
                    @change="getExternal"
                    return-object
                    required
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-divider />
              <v-layout wrap row>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="الشخص المسؤول"
                    placeholder="الشخص المسؤول"
                    v-model="person"
                    :rules="responsibleRules"
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap row>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="رقم الهاتف"
                    placeholder="00966011XXXXXXX"
                    v-model="currentExternal.mobile"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="الإيميل"
                    type="email"
                    placeholder="الإيميل"
                    v-model="currentExternal.email"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="نوع النشاط"
                    placeholder="'نوع النشاط"
                    v-model="currentExternal.activityType"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="الموقع"
                    placeholder="الموقع"
                    v-model="currentExternal.location"
                    readonly
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>

            <v-layout wrap>
              <v-flex class="mb-5">
                <h4 class="headline mb-0">
                  <b
                    >عزيزي العميل :لطفا ضع علامة في الخانة التي تمثل رضاك عن
                    النواحي التالية :</b
                  >
                </h4>
                <v-divider></v-divider>
                <v-layout
                  row-wrap
                  v-for="question in questionsAnswers"
                  :key="question.idQuestions"
                >
                  <v-flex xs12 md12 v-if="question.typeId == 1">
                    <v-text-field
                      outlined
                      v-model="question.questionText"
                      readonly
                    ></v-text-field>
                    <v-radio-group
                      @change="addAnswer($event, question)"
                      v-model="question.answer"
                      row
                    >
                      <v-radio
                        v-for="answer in question.answers"
                        :key="answer.ansId"
                        :label="answer.ans.answerText"
                        :value="answer.ansId"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 md12 v-else>
                    <v-text-field
                      label=""
                      placeholder=""
                      outlined
                      v-model="question.questionText"
                      readonly
                    ></v-text-field>
                    <v-textarea
                      @change="addAnswer($event, question)"
                      label=""
                      :placeholder="question.questionText"
                      v-model="question.answer"
                      outlined
                      rows="10"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="save"
              style="background:#58dd5b;color:white;margin-left:5px;"
              dark
            >
              <b>حفظ</b>
            </v-btn>
            <!--   <v-btn @click="reset" style="background:#5867dd;color:white;margin-left:5px;" dark>
                            <b>إعادة تعيين</b>
                        </v-btn>-->
            <!--    <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                            <b>رجوع</b>
                        </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "FillQuestionnaire",
  data() {
    return {
      allAnswers: [],
      questionsAnswers: [],
      questionnaire: null,
      person: "",
      questions: [],
      chosses: "",
      answers: [],
      manyAnswers: false,
      question: "",
      currentExternal: {
        mobile: "",
        email: "",
        activityType: "",
        location: ""
      },
      externalsRules: [
        v => !!v || "الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      external: "",
      externalsType: { item: "مصانع", value: 1 },
      externalsTypes: [
        { item: "اختر جهة خارجية", value: "0" },
        { item: "مشاريع", value: 2 },
        { item: "مقاولات", value: 3 },
        { item: "مباسط", value: 4 },
        { item: "مصانع", value: 1 },
        { item: "مواقف", value: 5 },
        { item: "ناقلات", value: 7 }
      ],
      externals: [{ item: "اختر جهة", value: "0" }],
      externalsTypeRules: [
        v => !!v || "نوع الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      location: "",
      activityType: "",
      date: new Date().toISOString().substr(0, 10),
      name: "",
      responsibleRules: [
        v => !!v || "المسؤول مطلوب",
        v => (v && v.length >= 3) || "المسؤول لا يجب أن يكون أقل من ثلاث محارف"
      ],
      responsible: "",
      code: "",
      phone: "",
      email: "",
      files: [],
      receivers: [],
      users: [],
      subject: "",
      body: "",
      valid: true
    };
  },
  methods: {
    addAnswer(event, data) {
      this.allAnswers.forEach(el => {
        if (el.QuestionId == data.QuestionId)
          if (data.typeId == 1) el.AnswerId = event;
          else el.AnswerTxt = event;
      });
    },
    async getExternal() {
      this.currentExternal = {
        mobile: "",
        email: "",
        activityType: "",
        location: ""
      };
      let url = null;
      if (this.externalsType.value == 7) {
        url = "Transporters/gettransporter?id=" + this.external.value;
      } else if (this.externalsType.value == 2) {
        url = "external/getproject?id=" + this.external.value;
      } else {
        url = "External/getexternal?id=" + this.external.value;
      }
      await this.ApiService.get(url)
        .then(res => {
          this.currentExternal = res.data.responseData[0];
        })
        .catch(() => {});
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async getExternals() {
      this.currentExternal = {
        mobile: "",
        email: "",
        activityType: "",
        location: ""
      };
      this.externals = [{ item: "اختر جهة", value: "0" }];
      if (this.externalsType.value == 2) {
        await this.ApiService.get("External/getallproject?id=2")
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              if (el != null)
                this.externals.push({ item: el.name, value: el.idproject });
            });
            this.external = this.externals[1];
            this.getExternal();
          })
          .catch(() => {});
      } else if (this.externalsType.value == 7) {
        await this.ApiService.get("Transporters/getalltransporters")
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              if (el != null)
                this.externals.push({ item: el.name, value: el.idtransporter });
            });
            this.external = this.externals[1];
            this.getExternal();
          })
          .catch(() => {});
      } else {
        await this.ApiService.get(
          "external/getallexternals?id=" + this.externalsType.value
        )
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              if (el != null)
                this.externals.push({ item: el.name, value: el.idexternal });
            });
            this.external = this.externals[1];
            this.getExternal();
          })
          .catch(() => {});
      }
    },
    getQuestions(questions) {
      questions.forEach(el => {
        if (el.typeId == 1)
          this.allAnswers.push({
            QuestionId: el.idQuestions,
            AnswerId: el.ansques[0].ansId
          });
        else
          this.allAnswers.push({
            QuestionId: el.idQuestions,
            AnswerTxt: ""
          });
      });
      questions.forEach(el => {
        if (el.typeId == 1)
          this.questionsAnswers.push({
            QuestionId: el.idQuestions,
            questionText: el.questionText,
            answers: el.ansques,
            answer: el.ansques[0].ansId,
            typeId: 1
          });
        else
          this.questionsAnswers.push({
            QuestionId: el.idQuestions,
            questionText: el.questionText,
            answer: "",
            typeId: 2
          });
      });
    },
    async getLastQuestionnaire() {
      await this.ApiService.get("Questionnaire/GetLastQuestionnaire")
        .then(res => {
          var questions = res.data.responseData.questionnaire.questions;
          this.questionnaire = res.data.responseData.questionnaire;
          questions.forEach(el => {
            if (el != null) this.questions.push(el);
          });
          this.getQuestions(this.questions);
        })
        .catch(() => {});
    },
    async save() {
      if (this.$refs.form.validate()) {
        let data = null;
        if (this.externalsType.value == 7) {
          data = {
            transport: this.external.value,
            questionnaire: this.questionnaire.idquestionnaire,
            date: this.date,
            responsiblePerson: this.person,
            answers: this.allAnswers
          };
        } else if (this.externalsType.value == 2) {
          data = {
            project: this.external.value,
            questionnaire: this.questionnaire.idquestionnaire,
            date: this.date,
            responsiblePerson: this.person,
            answers: this.allAnswers
          };
        } else {
          data = {
            external: this.external.value,
            questionnaire: this.questionnaire.idquestionnaire,
            date: this.date,
            responsiblePerson: this.person,
            answers: this.allAnswers
          };
        }
        await this.ApiService.post(
          "Questionnaire/Reply?user=" + this.user.id,
          data
        )
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getLastQuestionnaire();
    this.getExternals();
  }
};
</script>
<style scoped>
p {
  font-size: 15px;
}
</style>
